import { HttpService } from '@/services/HttpService';

class Playable {
  subdomain = '/playable';
  playableSaveLog(response) {
    return HttpService.post(`${this.subdomain}/savelog`, response);
  }
}

export default new Playable();
